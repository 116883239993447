import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../burns-ui-framework/shared/services/common/http.service';
import { LanguageService } from '../../../burns-ui-framework/shared/services/common/language-service.service';

import { Country } from '../models/country.model';
import { Level } from '../models/level.enum';
import { PlaceListItem } from '../models/place-list-item.model';
import { Place } from '../models/place.model';

import { String } from '../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class GeoService extends BaseSingletonService {

    private settings: {
        service: {
            getCountries: string;
            getPlaces: string;
            getPlace: string;
            getPlaceReverse: string;
        }
    };

    constructor(private http: HttpService, private languageService: LanguageService) {
        super('GeoService');
        this.settings = {
            service: {
                getCountries: '/geocoding/v1/geocoding-aggregation/countries?culture={0}',
                getPlaces: '/geocoding/v1/geocoding-aggregation/places?networkUid={0}&country={1}&term={2}&placeId={3}&lowerCornerLatitude={4}&lowerCornerLongitude={5}&upperCornerLatitude={6}&upperCornerLongitude={7}&level={8}&fullNameLevelFrom={9}&culture={10}',
                getPlace: '/geocoding/v1/geocoding-aggregation/place?placeId={0}&networkUid={1}&country={2}&name={3}&text={4}&fullNameLevelFrom={5}&culture={6}',
                getPlaceReverse: '/geocoding/v1/geocoding-aggregation/place-reverse?networkUid={0}&country={1}&longitude={2}&latitude={3}&level={4}&fullNameLevelFrom={5}&culture={6}'
            }
        };
    }

    public async getCountries(): Promise<Country[]> {
        const culture = this.languageService.retrieveLanguage();
        return this.http.get<{ list: Country[] }>(String.format(this.settings.service.getCountries, culture))
            .then(res => res.list);
    }

    public async getPlaces(networkUid: string, country: string, term: string, parentPlaceId: string, level: Level, fullNameLevelFrom: Level, lowerCornerLatitude: number = null, lowerCornerLongitude: number = null, upperCornerLatitude: number = null, upperCornerLongitude: number = null): Promise<PlaceListItem[]> {
        const culture = this.languageService.retrieveLanguage();
        return this.http.get<{ list: PlaceListItem[] }>(String.format(this.settings.service.getPlaces,
            networkUid,
            country,
            term,
            parentPlaceId,
            lowerCornerLatitude,
            lowerCornerLongitude,
            upperCornerLatitude,
            upperCornerLongitude,
            level,
            fullNameLevelFrom,
            culture)
        ).then(res => res.list);
    }

    public async getPlace(networkUid: string, placeId: string, country: string, name: string, text: string, fullNameLevelFrom: Level): Promise<Place> {
        const culture = this.languageService.retrieveLanguage();
        return this.http.get<{ data: Place }>(String.format(this.settings.service.getPlace,
            placeId,
            networkUid,
            country,
            name,
            text,
            fullNameLevelFrom,
            culture)
        ).then(res => res.data);
    }

    public async getPlaceReverse(networkUid: string, country: string, longitude: number, latitude: number, level: Level, fullNameLevelFrom: Level): Promise<Place> {
        const culture = this.languageService.retrieveLanguage();
        return this.http.get<{ data: Place }>(String.format(this.settings.service.getPlaceReverse,
            networkUid,
            country,
            longitude,
            latitude,
            level,
            fullNameLevelFrom,
            culture)
        ).then(res => res.data);
    }

    public async getCityList() {
        return Promise.resolve([]);
    }
}
