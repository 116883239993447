<section class="container">
    <div class="list-column" *ngFor="let enumItem of enumArray; let last = last" [ngClass]="{'last-column': last}">
        <exg-checkbox class="column-checkbox" [value]="listConfig[enumItem]" [textReverse]="true" (valueChange)="onChecked(enumItem, $event)">
            <span class="column-title">{{translations[enumItem] | translate}}</span>
        </exg-checkbox>
    </div>

    <div class="paging-box">
        <div class="paging-title">{{'Number of lines' | translate}}</div>

        <div class="btns">
            <exg-button *ngFor="let page of pages"
                        class="btn"
                        styleType="chips"
                        [color]="pageSize == page ? 'primary' : 'common'"
                        [class.selected]="pageSize == page"
                        (btnClick)="onPageSizeChanged(page)">{{page}}</exg-button>
        </div>
    </div>
</section>