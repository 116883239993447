import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { YandexPlaceComponent } from '../models/yandex-place-component.model';
import { YandexPlaceInputComponentType } from '../models/yandex-place-input-component-type.enum';

export class PlacesReverseAction {
    static readonly type = '[2GIS Places Reverse Page] Get Places Reverse';

    constructor(public payload: { latitude: number, longitude: number, level: YandexPlaceInputComponentType, fullNameLevelFrom: YandexPlaceInputComponentType }) { }
}

export class PlacesReverseSuccessAction {
    static readonly type = '[2GIS Places Reverse API] Get Places Reverse Success';

    constructor(public payload: YandexPlaceComponent[]) { }
}

export class PlacesReverseFailAction {
    static readonly type = '[2GIS Places Reverse API] Get Places Reverse Fail';

    constructor(public payload: ErrorObject) { }
}

export class PlacesReverseResetAction {
    static readonly type = '[2GIS Places Reverse API] Places Reverse Reset';
}
