
import { AreaCreateOrUpdateRequest } from '../../models/business/organization-pos/area-create-or-update-request.model';
import { AreaRestrictionCreateOrUpdateRequest } from '../../models/business/organization-pos/area-restriction-create-or-update-request.model';
import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

export class OrganizationPosAreaUpdateAction {
    static readonly type = '[Pos Page] GetOrganizationPosAreaUpdate';

    constructor(public payload: { uid: string, request: AreaCreateOrUpdateRequest, restrictions: AreaRestrictionCreateOrUpdateRequest }) { }
}

export class OrganizationPosAreaUpdateSuccessAction {
    static readonly type = '[PosPaymentTypes API] GetOrganizationPosAreaUpdate Success';

    constructor(public payload: { uid: string, area: AreaCreateOrUpdateRequest }) { }
}

export class OrganizationPosAreaUpdateFailAction {
    static readonly type = '[PosPaymentTypes API] GetOrganizationPosAreaUpdate Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationPosAreaUpdateResetAction {
    static readonly type = '[Pos Page] GetOrganizationPosAreaUpdate Reset';
}

