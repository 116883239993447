import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../shared/services/common/base-singleton.service';
import { GeoService } from '../../geocoding/services/geo.service';
import { YandexService } from '../../yandex-geocoding/services/yandex.service';

import { GeocodingMapper } from '../mappers/geocoding-mapper';

import { ComponentType } from '../../geocoding/models/component-type.enum';
import { GeocodingType } from '../models/geocoding-type.enum';
import { Geocoding } from '../models/geocoding.model';
import { YandexPlaceComponent } from '../../yandex-geocoding/models/yandex-place-component.model';

import { GeocodingUtils } from '../../geocoding/utils/geocoding-utils';
import { YandexGeocodingUtils } from '../../yandex-geocoding/utils/yandex-geocoding-utils';

@Injectable({
    providedIn: 'root'
})
export class GeocodingAggregationService extends BaseSingletonService {

    constructor(private yandexService: YandexService, private geoService: GeoService) {
        super('GeocodingAggregationService');
    }

    public async getPlacesReverse(geocodingType: GeocodingType, useHierarchy: boolean, countryCode: string, latitude: number, longitude: number, level?: ComponentType, fullNameLevelFrom?: ComponentType): Promise<Geocoding> {
        switch (geocodingType) {
            case GeocodingType.FiasMapbox:
                return this.geoService.getPlacesReverse(countryCode, latitude, longitude, level, fullNameLevelFrom)
                    .then(place => useHierarchy ? GeocodingUtils.cutPlaceByHierarchy(place) : place);

            case GeocodingType.Yandex:
                return this.yandexService.getPlacesReverse(latitude, longitude, GeocodingMapper.MapInputComponentType(level), GeocodingMapper.MapInputComponentType(fullNameLevelFrom))
                    .then(rawPlace => this.mapYandexGeocoding(rawPlace))
                    .then(place => useHierarchy ? YandexGeocodingUtils.cutPlaceByHierarchy(place) : place);
            default:
                return null;
        }
    }

    private mapYandexGeocoding(yandexPlaces: YandexPlaceComponent[]): Geocoding {
        if (yandexPlaces == null || yandexPlaces.length < 1) {
            return null;
        }

        const yandex = yandexPlaces[0];
        return GeocodingMapper.MapYandex(<any>yandex);
    }
}
