import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { filter, first } from 'rxjs';

import { PlacesForwardDispatchers } from '../../store/places-forward.dispatchers';
import { PlacesForwardSelectors } from '../../store/places-forward.selectors';
import { PlacesSuggestionsDispatchers } from '../../store/places-suggestions.dispatchers';
import { PlacesSuggestionsSelectors } from '../../store/places-suggestions.selectors';

import { YandexPlaceComponent } from '../../models/yandex-place-component.model';
import { YandexPlaceInputComponentType } from '../../models/yandex-place-input-component-type.enum';
import { YandexSuggestionPlaceComponent } from '../../models/yandex-suggestion-place-component.model';

import { YandexGeocodingUtils } from '../../utils/yandex-geocoding-utils';

@Component({
    selector: 'ye-yandex-geocoding',
    templateUrl: './ye-yandex-geocoding.component.html',
    styleUrls: ['./ye-yandex-geocoding.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => YeYandexGeocodingComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YeYandexGeocodingComponent implements ControlValueAccessor, OnChanges {
    @Input() value: any;
    @Input() maxlength: number;
    @Input() placeholder: string;
    @Input() validate: boolean;
    @Input() controlsToValidate: FormControl[];
    @Input() countryCode: string;
    @Input() readonly: boolean;
    @Input() styleType: 'common' | 'inline' | 'empty' | 'readonly' = 'common';
    @Input() allowNull: boolean;
    @Input() required: boolean;
    @Input() displayKey = 'fullName';
    @Input() emptyDisplayValue = '-';
    @Input() parentPlaceId: string = null;
    @Input() level: YandexPlaceInputComponentType = null;
    @Input() fullNameLevelFrom: YandexPlaceInputComponentType = null;
    @Input() lowerCornerLatitude: number;
    @Input() lowerCornerLongitude: number;
    @Input() upperCornerLatitude: number;
    @Input() upperCornerLongitude: number;
    @Input() useHierarchy: boolean;

    @Output() readonly selectionChange = new EventEmitter<YandexPlaceComponent & YandexSuggestionPlaceComponent>();

    public internalValue: any;
    public isDisabled: boolean;

    public places$ = this.placesSuggestionsSelectors.places$;

    private propagateChange: (_) => void;
    private propagateTouch: () => void;

    constructor(private changeDetectorRef: ChangeDetectorRef,
        private placesSuggestionsDispatchers: PlacesSuggestionsDispatchers,
        private placesSuggestionsSelectors: PlacesSuggestionsSelectors,
        private placesForwardDispatchers: PlacesForwardDispatchers,
        private placesForwardSelectors: PlacesForwardSelectors,
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.value && this.value !== this.internalValue) {
            this.writeValue(this.value);
        }
    }

    public addressDisplayValueFunction(item: YandexSuggestionPlaceComponent) {
        return `${item[this.displayKey]}`;
    }

    public onSearchPlace($event: string) {
        this.placesSuggestionsDispatchers.dispatchPlacesSuggestionsAction($event, this.level, this.fullNameLevelFrom, this.lowerCornerLatitude, this.lowerCornerLongitude, this.upperCornerLatitude, this.upperCornerLongitude);
    }

    public onSelectionChange($event: YandexSuggestionPlaceComponent) {
        this.placesSuggestionsDispatchers.dispatchPlacesSuggestionsResetAction();
        if (!!$event && !!$event.fullName) {
            this.placesForwardDispatchers.dispatchPlacesForwardAction($event.uri);
            this.placesForwardSelectors.retrieved$.pipe(filter(x => !!x), first()).subscribe(() => {
                this.placesForwardSelectors.places$.pipe(first()).subscribe(places => {
                    this.placesForwardDispatchers.dispatchPlacesForwardResetAction();
                    this.updateValue(!!places && !!places[0] ? places[0] : $event);
                });
            });
        } else {
            this.updateValue($event);
        }
    }

    public updateValue($event) {
        let result = $event;
        if (this.useHierarchy && $event.components && $event.components.length > 0) {
            result = YandexGeocodingUtils.cutPlaceByHierarchy($event);
        }

        this.internalValue = result;

        if (this.propagateTouch) {
            this.propagateTouch();
        }
        if (this.propagateChange) {
            this.propagateChange(this.internalValue);
        }

        this.selectionChange.emit(result);
    }

    public writeValue(value: string) {
        this.internalValue = value;
        this.changeDetectorRef.markForCheck();
    }

    public registerOnChange(fn) {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn) {
        this.propagateTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
}
