import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CorporationCitiesService } from '../../services/business/corporation-cities.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';

import { CorporationCitiesCreateAction, CorporationCitiesCreateFailAction, CorporationCitiesCreateResetAction, CorporationCitiesCreateSuccessAction } from './corporation-cities-create.actions';

export interface CorporationCitiesCreateStateModel {
    created: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<CorporationCitiesCreateStateModel>({
    name: 'CorporationCitiesCreate',
    defaults: { pending: false, created: false, error: null }
})
@Injectable()
export class CorporationCitiesCreateState {
    constructor(private corporationCreateService: CorporationCitiesService) {
    }

    @Action([CorporationCitiesCreateAction]) productsSearchGet(ctx: StateContext<CorporationCitiesCreateStateModel>, action: CorporationCitiesCreateAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, created: false, error: null });

        return this.corporationCreateService.createCity(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new CorporationCitiesCreateSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CorporationCitiesCreateFailAction(err)), 0));
    }

    @Action(CorporationCitiesCreateSuccessAction) CorporationCitiesCreateGetSuccess(ctx: StateContext<CorporationCitiesCreateStateModel>, action: CorporationCitiesCreateSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: true, error: null });
    }

    @Action(CorporationCitiesCreateFailAction) CorporationCitiesCreateGetFail(ctx: StateContext<CorporationCitiesCreateStateModel>, action: CorporationCitiesCreateFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CorporationCitiesCreateResetAction) CorporationCitiesCreateGetReset(ctx: StateContext<CorporationCitiesCreateStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, created: false, error: null });
    }
}
