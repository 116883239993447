import { Geocoding } from '../models/geocoding.model';

export class GeocodingUtils {

    public static getAddressUid(address: any): any {
        return address && address.id
            ? address.id
            : address && address.placeId
                ? address.placeId
                : null;
    }

    public static cutPlaceByHierarchy(place: Geocoding) {
        const components = [...place.components];
        const sortedComponents = components.sort((c1, c2) => c2.componentType - c1.componentType);
        return {
            id: sortedComponents[0].id,
            fullName: sortedComponents[0].name,
            zip: place.zip,
            text: null,
            name: sortedComponents[0].name,
            longitude: place.longitude,
            latitude: place.latitude,
            componentType: sortedComponents[0].componentType,
            parentPlaceId: place.parentPlaceId
        };
    }
}
