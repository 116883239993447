import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';
import { TokenService } from '../../../../burns-ui-framework/shared/services/common/token.service';

import { NetworkSettings } from '../../models/business/networks/network-settings.model';

import { ExgBaseParamsConfig } from '../../../../burns-ui-framework/shared/exg-params.config';

import { JwtUtils } from '../../../../burns-ui-framework/shared/utils/jwt-utils';

@Injectable({
    providedIn: 'root'
})
export class NetworkService extends BaseSingletonService {

    constructor(private cookieService: ExgCookieService, private tokenService: TokenService) {
        super('NetworkService');
    }

    public getUserNetworkId() {
        const authToken = this.tokenService.getAuthToken();
        if (!authToken) return '';
        const token = JwtUtils.decode<{ network_id: string }>(authToken);
        if (!token) return '';
        return token.network_id || '';
    }

    public storeNetwork(network: NetworkSettings) {
        const settings = JSON.stringify(network);
        this.cookieService.setCookie(ExgBaseParamsConfig.storageKeys.storageNetwork, settings);
    }

    public retrieveNetwork(): NetworkSettings {
        const settings = this.cookieService.getCookie(ExgBaseParamsConfig.storageKeys.storageNetwork);
        return JSON.parse(settings);
    }
}
