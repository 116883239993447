import { Injectable } from '@angular/core';

import { Action, State, StateContext } from '@ngxs/store';

import { GeocodingAggregationService } from '../services/geocoding-aggregation.service';

import { AggregationPlacesReverseAction, AggregationPlacesReverseFailAction, AggregationPlacesReverseResetAction, AggregationPlacesReverseSuccessAction } from './aggregation-places-reverse.actions';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { Geocoding } from '../models/geocoding.model';

export interface AggregationPlacesReverseStateModel {
    pending: boolean;
    entity: Geocoding;
    retrieved: boolean;
    error: ErrorObject;
}

@State<AggregationPlacesReverseStateModel>({
    name: 'AggregationPlacesReverse',
    defaults: { pending: false, entity: null, retrieved: false, error: null }
})
@Injectable()
export class AggregationPlacesReverseState {
    constructor(private geocodingAggregationService: GeocodingAggregationService) { }

    @Action([AggregationPlacesReverseAction]) async placesReverseGet(ctx: StateContext<AggregationPlacesReverseStateModel>, action: AggregationPlacesReverseAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.geocodingAggregationService.getPlacesReverse(action.payload.geocodingType, action.payload.useHierarchy, action.payload.countryCode, action.payload.latitude, action.payload.longitude, action.payload.level, action.payload.fullNameLevelFrom)
            .then(resp => setTimeout(() => ctx.dispatch(new AggregationPlacesReverseSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new AggregationPlacesReverseFailAction(err)), 0));
    }

    @Action(AggregationPlacesReverseSuccessAction) placesReverseGetSuccess(ctx: StateContext<AggregationPlacesReverseStateModel>, action: AggregationPlacesReverseSuccessAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: false, entity: action.payload, retrieved: true, error: null });
    }

    @Action(AggregationPlacesReverseFailAction) placesReverseGetFail(ctx: StateContext<AggregationPlacesReverseStateModel>, action: AggregationPlacesReverseFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(AggregationPlacesReverseResetAction) placesReverseGetReset(ctx: StateContext<AggregationPlacesReverseStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: null, retrieved: false, error: null });
    }
}
