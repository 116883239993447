import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { OrderCreateOrUpdateRequest } from '../../models/business/orders/order-create-or-update-request.model';
import { OrderDiscountGetFilterData } from '../../models/filters/order-discount-get-filter.model';
import { OrderDiscountListItem } from '../../models/business/orders/order-discount-list-item.model';
import { OrderHistoryListGetFilter } from '../../models/filters/order-history-list-get-filter.model';
import { OrderHistoryListItem } from '../../models/business/orders/order-history-list-item.model';
import { OrderListGetFilter } from '../../models/filters/order-list-get-filter.model';
import { OrderListItem } from '../../models/business/orders/order-list-item.model';
import { OrderStatusUpdateRequest } from '../../models/business/orders/order-status-update-request.model';
import { OrderSummary } from '../../models/business/orders/order-summary.model';
import { Order } from '../../models/business/orders/order.model';
import { PaginationResultWithSummary } from '../../models/business/common/pagination-result-with-summary';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class OrdersService extends BaseSingletonService {

    private settings: {
        service: {
            getOrder: string;
            getOrderList: string;
            createOrder: string;
            updateOrder: string;
            deleteOrder: string;
            getOrderHistory: string;
            updateOrderStatus: string;
            getOrderDiscounts: string;
        }
    };

    constructor(private http: HttpService) {
        super('OrdersService');
        this.settings = {
            service: {
                getOrder: '/orders-aggregation/v1/orders/{0}',
                getOrderList: '/orders-aggregation/v1/orders/filter',
                createOrder: '/orders-aggregation/v1/orders',
                updateOrder: '/orders-aggregation/v1/orders/{0}',
                deleteOrder: '/orders/v1/orders/{0}',
                updateOrderStatus: '/orders/v1/orders/{0}/status',
                getOrderHistory: '/orders/v1/orders/history?contactUid={0}&sortField={1}&sortOrder={2}&pageIndex={3}&pageSize={4}',
                getOrderDiscounts: '/loyalty/v1/organization-discounts?franchiseeUid={0}&organizationUid={1}&organizationPosUid={2}'
            }
        };
    }

    public async getOrder(uid: string): Promise<Order> {
        return this.http.get<{ data: Order }>(String.format(this.settings.service.getOrder, uid))
            .then(res => res.data);
    }

    public async getOrderList(filter: OrderListGetFilter): Promise<PaginationResultWithSummary<OrderListItem, OrderSummary>> {
        return this.http.post<PaginationResultWithSummary<OrderListItem, OrderSummary>>(String.format(this.settings.service.getOrderList), { ...filter, term: filter.term })
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async createOrder(request: OrderCreateOrUpdateRequest): Promise<Order> {
        return this.http.post<{ data: Order }>(String.format(this.settings.service.createOrder), request)
            .then(res => res.data);
    }

    public async updateOrder(uid: string, request: OrderCreateOrUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateOrder, uid), request);
    }

    public async updateOrderStatus(uid: string, request: OrderStatusUpdateRequest): Promise<boolean> {
        return this.http.put(String.format(this.settings.service.updateOrderStatus, uid), request);
    }

    public async deleteOrder(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deleteOrder, uid));
    }

    public async getOrderHistory(filter: OrderHistoryListGetFilter): Promise<PaginationResult<OrderHistoryListItem>> {
        return this.http.get<PaginationResult<OrderHistoryListItem>>(String.format(this.settings.service.getOrderHistory, filter.contactUid, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getOrderDiscounts(filter: OrderDiscountGetFilterData): Promise<PaginationResult<OrderDiscountListItem>> {
        return this.http.get(String.format(this.settings.service.getOrderDiscounts, filter.franchiseeUid, filter.organizationUid, filter.organizationPosUid));
    }
}
