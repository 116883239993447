import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { OrganizationCityCreateRequest } from '../../models/business/organizations/organization-city-create-request.model';
import { OrganizationCity } from '../../models/business/organizations/organization-city.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class CorporationCitiesService extends BaseSingletonService {

    private settings: {
        service: {
            getCities: string;
            createCity: string;
        }
    };

    constructor(private http: HttpService) {
        super('CorporationCitiesService');
        this.settings = {
            service: {
                getCities: '/corporation/v1/cities?culture={0}',
                createCity: '/corporation/v1/cities'
            }
        };
    }

    public async getCities(culture: string): Promise<OrganizationCity[]> {
        return this.http.get<{ list: OrganizationCity[] }>(String.format(this.settings.service.getCities, culture))
            .then(res => res.list);
    }

    public async createCity(request: OrganizationCityCreateRequest): Promise<OrganizationCity> {
        return this.http.post<{ data: OrganizationCity }>(String.format(this.settings.service.createCity), request)
            .then(res => res.data);
    }
}
