import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { ComponentType } from '../../models/component-type.enum';
import { GeocodingType } from '../../models/geocoding-type.enum';
import { Geocoding } from '../../models/geocoding.model';
import { Geocoding as FiasGeocoding } from '../../../geocoding/models/geocoding.model';
import { YandexPlaceComponent } from '../../../yandex-geocoding/models/yandex-place-component.model';
import { YandexSuggestionPlaceComponent } from '../../../yandex-geocoding/models/yandex-suggestion-place-component.model';

import { GeocodingMapper } from '../../mappers/geocoding-mapper';

import { DEFAULT_GEOCODING_TOKEN } from '../../geocoding-tokens';

@Component({
    selector: 'exg-address-aggregation-autocomplete',
    templateUrl: './exg-address-aggregation-autocomplete.component.html',
    styleUrls: ['./exg-address-aggregation-autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ExgAddressAggregationAutocompleteComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExgAddressAggregationAutocompleteComponent implements ControlValueAccessor, OnChanges {
    @Input() geocoder: GeocodingType;

    @Input() maxlength: number;
    @Input() placeholder: string;
    @Input() validate: boolean;
    @Input() controlsToValidate: FormControl[];
    @Input() countryCode: string;
    @Input() readonly: boolean;
    @Input() styleType: 'common' | 'inline' | 'empty' | 'readonly' = 'common';
    @Input() allowNull: boolean;
    @Input() emptyDisplayValue = '-';
    @Input() parentPlaceId: string = null;
    @Input() level: ComponentType = null;
    @Input() fullNameLevelFrom: ComponentType = null;
    @Input() useHierarchy: boolean;
    @Input() required: boolean;

    @Input() displayKey = 'fullName';
    @Input() lowerCornerLatitude: number;
    @Input() lowerCornerLongitude: number;
    @Input() upperCornerLatitude: number;
    @Input() upperCornerLongitude: number;

    @Output() readonly selectionChange = new EventEmitter<Geocoding>();

    public value: any;
    public isDisabled: boolean;

    public geocodingType: GeocodingType;
    public geocodingTypeEnum = GeocodingType;

    private propagateChange: (_) => void;
    private propagateTouch: () => void;

    constructor(@Inject(DEFAULT_GEOCODING_TOKEN) private defaultGeocoding: GeocodingType,
                private changeDetectorRef: ChangeDetectorRef) {
        this.geocodingType = defaultGeocoding;
        this.setupGeocoder(defaultGeocoding);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.geocoder && this.geocoder) {
            this.setupGeocoder(this.geocoder);
        }
    }

    public addressDisplayValueFunction(item: Geocoding) {
        return `${item.fullName || item.name || ''} ${item.text || ''}`;
    }

    public onFiasSelectionChange($event: FiasGeocoding) {
        const place = !!$event ? GeocodingMapper.MapFias($event) : null;
        this.onSelectionChange(place);
    }

    public onYandexSelectionChange($event: YandexPlaceComponent & YandexSuggestionPlaceComponent) {
        const place = !!$event ? GeocodingMapper.MapYandex($event) : null;
        this.onSelectionChange(place);
    }

    public writeValue(value: string) {
        this.value = value;
        this.changeDetectorRef.markForCheck();
    }

    public registerOnChange(fn) {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn) {
        this.propagateTouch = fn;
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    private setupGeocoder(geocoding: GeocodingType) {
        this.geocodingType = geocoding;
    }

    private onSelectionChange($event: Geocoding) {
        this.value = $event;

        if (this.propagateTouch) {
            this.propagateTouch();
        }
        if (this.propagateChange) {
            this.propagateChange(this.value);
        }

        this.selectionChange.emit($event);
    }
}
