import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { CorporationCitiesService } from '../../services/business/corporation-cities.service';

import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationCity } from '../../models/business/organizations/organization-city.model';

import { CorporationCitiesCreateSuccessAction } from './corporation-cities-create.actions';
import { CorporationCitiesAction, CorporationCitiesFailAction, CorporationCitiesResetAction, CorporationCitiesSuccessAction } from './corporation-cities.actions';

export interface CorporationCitiesStateModel {
    entities: OrganizationCity[];
    retrieved: boolean;
    pending: boolean;
    error: ErrorObject;
}

@State<CorporationCitiesStateModel>({
    name: 'CorporationCities',
    defaults: { pending: false, entities: [], retrieved: false, error: null }
})
@Injectable()
export class CorporationCitiesState {
    constructor(private corporationCitiesService: CorporationCitiesService) {
    }

    @Action([CorporationCitiesAction]) productsSearchGet(ctx: StateContext<CorporationCitiesStateModel>, action: CorporationCitiesAction) {
        const state = ctx.getState();

        ctx.setState({ ...state, pending: true, error: null });

        return this.corporationCitiesService.getCities(action.payload)
            .then(resp => setTimeout(() => ctx.dispatch(new CorporationCitiesSuccessAction({ list: resp })), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new CorporationCitiesFailAction(err)), 0));
    }

    @Action(CorporationCitiesSuccessAction) CorporationCitiesGetSuccess(ctx: StateContext<CorporationCitiesStateModel>, action: CorporationCitiesSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: action.payload.list, retrieved: true, error: null });
    }

    @Action(CorporationCitiesFailAction) CorporationCitiesGetFail(ctx: StateContext<CorporationCitiesStateModel>, action: CorporationCitiesFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(CorporationCitiesResetAction) CorporationCitiesGetReset(ctx: StateContext<CorporationCitiesStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entities: [], retrieved: false, error: null });
    }

    @Action(CorporationCitiesCreateSuccessAction) CorporationCitiesCreateGetSuccess(ctx: StateContext<CorporationCitiesStateModel>, action: CorporationCitiesCreateSuccessAction) {
        const state = ctx.getState();
        const entities = [...state.entities, action.payload];
        ctx.setState({ ...state, pending: false, entities, error: null });
    }
}
