import { PlatformLocation } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { filter } from 'rxjs/operators';

import { AppSelectors } from './burns-ui-framework/shared/store/app.selectors';
import { AuthService } from './burns-ui-framework/shared/services/business/auth.service';
import { BaseSingletonService } from './burns-ui-framework/shared/services/common/base-singleton.service';
import { CorporationNetworksService } from './evasys/shared/services/business/corporation-networks.service';
import { CurrencyService } from './burns-ui-framework/shared/services/common/currency-service.service';
import { DialogService } from './burns-ui-framework/shared/services/common/dialog.service';
import { ExgTranslateService } from './burns-ui-framework/shared/services/common/exg-translate.service';
import { LanguageService } from './burns-ui-framework/shared/services/common/language-service.service';
import { LogoutDispatchers } from './burns-ui-framework/shared/store/logout/logout.dispatchers';
import { LogoutSelectors } from './burns-ui-framework/shared/store/logout/logout.selectors';
import { NetworkService } from './evasys/shared/services/common/network.service';
import { ProfileDispatchers } from './burns-ui-framework/shared/store/profile/profile.dispatchers';
import { ProfileSelectors } from './burns-ui-framework/shared/store/profile/profile.selectors';
import { PusherService } from './burns-ui-framework/shared/services/common/pusher.service';
import { RouterExtService } from './burns-ui-framework/shared/services/common/router-ext.service';
import { UiSelectors } from './burns-ui-framework/shared/store/ui/ui.selectors';

import { ExgCultureEnum } from './burns-ui-framework/shared/models/common/exg-culture.model';
import { ExgParamsConfig } from './ye-shared/exg-params.config';

import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    styleUrls: ['./app.component.scss'],
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    @ViewChild('exgDialogServiceContainer', { read: ViewContainerRef, static: true }) dialogDynamicComponentContainer: ViewContainerRef;

    public profile$ = this.profileSelectors.profile$;

    public loggingOut$ = this.logoutSelectors.pending$;
    public culture$ = this.uiSelectors.culture$;

    public isLoading: boolean;

    constructor(private authService: AuthService,
                private platformLocation: PlatformLocation,
                private meta: Meta,
                private router: Router,
                private routerExtService: RouterExtService,
                private dialogService: DialogService,
                private translate: ExgTranslateService,
                private appSelectors: AppSelectors,
                private profileSelectors: ProfileSelectors,
                private profileDispatchers: ProfileDispatchers,
                private languageService: LanguageService,
                private pusherService: PusherService,
                private currencyService: CurrencyService,
                private uiSelectors: UiSelectors,
                private logoutSelectors: LogoutSelectors,
                private logoutDispatchers: LogoutDispatchers,
                private networkService: NetworkService,
                private corporationNetworksService: CorporationNetworksService) {
        this.translate.setDefaultLang(<ExgCultureEnum>environment.defaultCulture || ExgCultureEnum.Russian);
        this.languageService.storeLanguage(<ExgCultureEnum>environment.defaultCulture || ExgCultureEnum.Russian);
        this.translate.apply();
        this.culture$.pipe(filter(x => !!x)).subscribe((culture) => {
            this.languageService.storeLanguage(culture);
            this.translate.apply();
        });

        this.currencyService.storeCurrency(environment.defaultCurrency);

        this.profile$.pipe(filter(x => !!x)).subscribe((profule) => {
            this.initHubs();
            this.languageService.storeLanguage(profule.user.culture);
            this.translate.apply();

            this.corporationNetworksService.getNetworkSettings()
                .then(settings => {
                    this.networkService.storeNetwork(settings);
                    this.currencyService.storeCurrency(settings.currencyCode);
                })
                .catch(() => {
                    this.logoutDispatchers.dispatchLogoutAction();
                    this.logoutDispatchers.dispatchFullResetAction();
                    this.router.navigate(['/auth']);
                });
        });
    }

    public ngOnInit() {
        BaseSingletonService.initialized = {};
        this.dialogService.init(this.dialogDynamicComponentContainer);

        this.initProfile();
        this.initOgMetaTags();
        this.routerExtService.init();
    }

    public ngOnDestroy() {
        // force clean singleton services initialization
        BaseSingletonService.initialized = {};
    }

    private initProfile() {
        if (this.authService.isLoggedIn()) {
            const userUid = this.authService.getUserId();
            this.profileDispatchers.dispatchProfileAction(userUid, 'authGuard');
        }
    }

    private initOgMetaTags() {
        this.appSelectors.getRoute().subscribe(event => this.setOpenGraphMetaTags(event.url));
    }

    private setOpenGraphMetaTags(url: string) {
        let origin = `${this.platformLocation.protocol}//${this.platformLocation.hostname}`;
        origin += this.platformLocation.port ? `:${this.platformLocation.port}` : '';

        this.meta.updateTag({ property: 'og:url', content: `${origin}${url}` });
        this.meta.updateTag({ property: 'og:type', content: 'website' });
    }

    private initHubs() {
        const hubs = [
            { hubName: ExgParamsConfig.signalR.identity.OnNameChanged.hub, withAuth: true },
            { hubName: ExgParamsConfig.signalR.orders.OnOrderCreated.hub, withAuth: true },
            { hubName: ExgParamsConfig.signalR.callCenter.OnClientCallFinished.hub, withAuth: true },
            { hubName: ExgParamsConfig.signalR.productivity.OnIntervalsUpdated.hub, withAuth: true },
        ];

        this.pusherService.init(environment.apiUrl, hubs);
        this.pusherService.reconnect();
    }
}
