import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { NetworkSettings } from '../../models/business/networks/network-settings.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class CorporationNetworksService extends BaseSingletonService {

    private settings: {
        service: {
            getNetworkSettings: string;
        }
    };

    constructor(private http: HttpService) {
        super('CorporationNetworksService');
        this.settings = {
            service: {
                getNetworkSettings: '/corporation/v1/networks/settings'
            }
        };
    }

    public async getNetworkSettings(): Promise<NetworkSettings> {
        return this.http.get<{ data: NetworkSettings }>(String.format(this.settings.service.getNetworkSettings))
            .then(res => res.data);
    }
}
