import { ComponentType } from '../../geocoding/models/component-type.enum';
import { ErrorObject } from '../../shared/models/common/error-object.model';
import { GeocodingType } from '../models/geocoding-type.enum';
import { Geocoding } from '../models/geocoding.model';

export class AggregationPlacesReverseAction {
    static readonly type = '[Aggregation Places Reverse Page] Get Places Reverse';

    constructor(public payload: { geocodingType: GeocodingType, useHierarchy: boolean, countryCode: string, latitude: number, longitude: number, level?: ComponentType, fullNameLevelFrom?: ComponentType }) { }
}

export class AggregationPlacesReverseSuccessAction {
    static readonly type = '[Aggregation Places Reverse API] Get Places Reverse Success';

    constructor(public payload: Geocoding) { }
}

export class AggregationPlacesReverseFailAction {
    static readonly type = '[Aggregation Places Reverse API] Get Places Reverse Fail';

    constructor(public payload: ErrorObject) { }
}

export class AggregationPlacesReverseResetAction {
    static readonly type = '[Aggregation Places Reverse API] Places Reverse Reset';
}
