import { CurrencyPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, type PipeTransform } from '@angular/core';

import { CurrencyService } from '../../services/common/currency-service.service';
import { ExgLocaleId } from '../../providers/exg-locale-id.provider';

@Pipe({ name: 'exgCurrency', pure: false })
export class ExgCurrencyPipe implements PipeTransform {

    constructor(private currencyService: CurrencyService, @Inject(LOCALE_ID) public localeId: ExgLocaleId) { }

    public transform(value: number | string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, currencyCode?: string): string {
        const _currencyCode = currencyCode || this.currencyService.retrieveCurrency();
        const _display = display || 'symbol';
        const _digitsInfo = digitsInfo || '1.0-1';
        const _locale = this.localeId.toString();

        return new CurrencyPipe(_locale).transform(value, _currencyCode, _display, _digitsInfo, _locale);
    }
}
