export enum ExgCurrencyEnum {
    English = 'USD',
    Russian = 'RUB',
    Euro = 'EUR',
    Dirham = 'AED',
    BelarusRub = 'BYN',
    Dram = 'AMD'
}

export type ExgСurrencies = ExgCurrencyEnum.Russian | ExgCurrencyEnum.English | ExgCurrencyEnum.Euro | ExgCurrencyEnum.Dirham | ExgCurrencyEnum.BelarusRub | ExgCurrencyEnum.Dram;
