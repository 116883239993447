import { isPlatformBrowser, registerLocaleData } from '@angular/common';
import { HttpBackend } from '@angular/common/http';
import localeRu from '@angular/common/locales/ru';
import { APP_ID, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { CookieModule } from 'ngx-cookie';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';

import { AppComponent } from '../app/app.component';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './burns-ui-framework/shared/shared.module';
import { SharedEvaModule } from './evasys/shared/shared.module';
import { YeModule } from './ye-shared/ye-shared.module';

import { DialogWebService } from './burns-ui-framework/shared/services/common/dialog-web.service';
import { DialogService } from './burns-ui-framework/shared/services/common/dialog.service';
import { LocalStorageWebService } from './burns-ui-framework/shared/services/common/local-storage-web.service';
import { LocalStorageService } from './burns-ui-framework/shared/services/common/local-storage.service';
import { PlatformWebService } from './burns-ui-framework/shared/services/common/platform-web.service';
import { PlatformService } from './burns-ui-framework/shared/services/common/platform.service';
import { SnackbarWebService } from './burns-ui-framework/shared/services/common/snackbar-web.service';
import { SnackbarService } from './burns-ui-framework/shared/services/common/snackbar.service';

import { environment } from '../environments/environment';
import { states } from './ye-shared/reducers';

import { EvaSiteSettingsProviders } from './ye-shared/eva-settngs-providers.config';
import { yeSharedSettingsConfig } from './ye-shared/shared-settings.config';
import { SiteSettingsProviders } from './ye-shared/site-settings-providers.config';

registerLocaleData(localeRu, 'ru');

export function createTranslateLoader(http: HttpBackend) {
    return new MultiTranslateHttpLoader(http, [{ prefix: '/assets/i18n/', suffix: '.json' }]);
}

@NgModule({
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        TranslateModule.forRoot({ loader: { provide: TranslateLoader, useFactory: createTranslateLoader, deps: [HttpBackend] }, isolate: false }),
        CookieModule.withOptions(),

        NgxsModule.forRoot(states, { developmentMode: !environment.production }),
        NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
        NgxsResetPluginModule.forRoot(),
        NgxsRouterPluginModule.forRoot(),

        SharedEvaModule.forRoot(EvaSiteSettingsProviders.getProviders),
        SharedModule.forRoot(yeSharedSettingsConfig),
        YeModule.forRoot(SiteSettingsProviders.getProviders),
        AppRoutingModule
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        { provide: PlatformService, useClass: PlatformWebService },
        { provide: LocalStorageService, useClass: LocalStorageWebService },
        { provide: SnackbarService, useClass: SnackbarWebService },
        { provide: DialogService, useClass: DialogWebService }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(@Inject(PLATFORM_ID) private platformId: object, @Inject(APP_ID) private appId: string) {
        const platform = isPlatformBrowser(this.platformId) ? 'in the browser' : 'on the server';
        // tslint:disable-next-line:no-console
        console.log(`Running ${platform} with appId=${this.appId}`);
    }
}
