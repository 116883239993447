import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationCityCreateRequest } from '../../models/business/organizations/organization-city-create-request.model';
import { OrganizationCity } from '../../models/business/organizations/organization-city.model';

export class CorporationCitiesCreateAction {
    static readonly type = '[CorporationCitiesCreate Page] GetCorporationCitiesCreate';

    constructor(public payload: OrganizationCityCreateRequest) { }
}

export class CorporationCitiesCreateSuccessAction {
    static readonly type = '[CorporationCitiesCreate API] GetCorporationCitiesCreate Success';

    constructor(public payload: OrganizationCity) { }
}

export class CorporationCitiesCreateFailAction {
    static readonly type = '[CorporationCitiesCreate API] GetCorporationCitiesCreate Fail';

    constructor(public payload: ErrorObject) { }
}

export class CorporationCitiesCreateResetAction {
    static readonly type = '[CorporationCitiesCreate Page] GetCorporationCitiesCreate Reset';
}

