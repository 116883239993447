import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { OrganizationCity } from '../../models/business/organizations/organization-city.model';

export class CorporationCitiesAction {
    static readonly type = '[CorporationCities Page] GetCorporationCities';

    constructor(public payload: string) { }
}

export class CorporationCitiesSuccessAction {
    static readonly type = '[CorporationCities API] GetCorporationCities Success';

    constructor(public payload: { list: OrganizationCity[] }) { }
}

export class CorporationCitiesFailAction {
    static readonly type = '[CorporationCities API] GetCorporationCities Fail';

    constructor(public payload: ErrorObject) { }
}

export class CorporationCitiesResetAction {
    static readonly type = '[CorporationCities Page] GetCorporationCities Reset';
}

