import { ErrorObject } from '../../../../burns-ui-framework/shared/models/common/error-object.model';
import { PosDetailed } from '../../models/business/organization-pos/pos-detailed.model';

export class OrganizationPosByCoordinatesGetAction {
    static readonly type = '[OrganizationPosByCoordinates Page] GetOrganizationPosByCoordinates Get';

    constructor(public payload: { longitude: number, latitude: number, date: number }) { }
}

export class OrganizationPosByCoordinatesGetSuccessAction {
    static readonly type = '[OrganizationPosByCoordinates API] GetOrganizationPosByCoordinates Success';

    constructor(public payload: PosDetailed) { }
}

export class OrganizationPosByCoordinatesFailAction {
    static readonly type = '[OrganizationPosByCoordinates API] GetOrganizationPosByCoordinates Fail';

    constructor(public payload: ErrorObject) { }
}

export class OrganizationPosByCoordinatesResetAction {
    static readonly type = '[OrganizationPosByCoordinates Page] GetOrganizationPosByCoordinates Reset';
}
