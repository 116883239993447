import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { ExgCookieService } from '../../../../burns-ui-framework/shared/services/common/exg-cookie.service';

import { NetworkSettings } from '../../models/business/networks/network-settings.model';

import { ExgBaseParamsConfig } from '../../../../burns-ui-framework/shared/exg-params.config';

@Injectable({
    providedIn: 'root'
})
export class NetworkService extends BaseSingletonService {

    constructor(private cookieService: ExgCookieService) {
        super('NetworkService');
    }

    public storeNetwork(network: NetworkSettings) {
        const settings = JSON.stringify(network);
        this.cookieService.setCookie(ExgBaseParamsConfig.storageKeys.storageNetwork, settings);
    }

    public retrieveNetwork(): NetworkSettings {
        const settings = this.cookieService.getCookie(ExgBaseParamsConfig.storageKeys.storageNetwork);
        return JSON.parse(settings);
    }
}
