export class YandexGeocodingUtils {
    public static cutPlaceByHierarchy(place: any) {
        const components = [...place.components];
        const sortedComponents = components.sort((c1, c2) => c2.componentType - c1.componentType);
        return {
            ...place,
            fullName: sortedComponents[0].name,
            name: sortedComponents[0].name,
            componentType: sortedComponents[0].componentType
        };
    }
}
