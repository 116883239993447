export enum ExgCultureEnum {
    English = 'en-US',
    EnglishAE = 'en-AE',
    Russian = 'ru-RU',
    Belarus = 'ru-BY',
    Armenian = 'hy-AM',
    Azerbaijan = 'az-AZ'
}

export type ExgCultures = ExgCultureEnum.Russian | ExgCultureEnum.English | ExgCultureEnum.EnglishAE | ExgCultureEnum.Belarus | ExgCultureEnum.Armenian | ExgCultureEnum.Azerbaijan;
