import { LOCALE_ID, Provider } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export class ExgLocaleId extends String {
    constructor(private translate: TranslateService) {
        super();
    }

    override toString(): string {
        return this.translate.currentLang;
    }

    override valueOf(): string {
        return this.toString();
    }
}

export const EXG_LOCALE_ID: Provider = {
    provide: LOCALE_ID,
    useClass: ExgLocaleId,
    deps: [TranslateService],
};
