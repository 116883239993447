<exg-autocomplete class="control"
                  [maxlength]="maxlength" 
                  [data]="places$ | async"
                  [placeholder]="placeholder"
                  [value]="internalValue"
                  [readonly]="readonly"
                  [styleType]="styleType"
                  [allowNull]="allowNull"
                  [required]="required"
                  [emptyDisplayValue]="emptyDisplayValue"
                  [displayValueFunction]="addressDisplayValueFunction.bind(this)"
                  [displayValueTemplate]="addressDisplayValueTemplate"
                  [validate]="validate"
                  [controlsToValidate]="controlsToValidate"
                  (valueChange)="onSearchPlace($event)"
                  (selectionChange)="onSelectionChange($event)"></exg-autocomplete>
<ng-template #addressDisplayValueTemplate let-val>
    <div class="address-box">
        <span>{{ val.fullName || val.name || val[displayKey] }}</span>
    </div>
</ng-template>