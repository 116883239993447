<button class="btn" type="button" (click)="onOpenOrderClick()">
    <div class="full-name number" *ngIf="listConfig[orderSortingFields.InternalNumber]" [class.active]="filter.sortField === orderSortingFields.InternalNumber">
        <span title="{{order.internalNumber}}">{{order.internalNumber}}</span>
    </div>
    <div class="last-entrance client" *ngIf="listConfig[orderSortingFields.Company]" [class.active]="filter.sortField === orderSortingFields.Company">
        <span title="{{order.company?.name}}">{{order.company?.name}}</span>
    </div>
    <div class="full-name amount" *ngIf="listConfig[orderSortingFields.TotalAmount]" [class.active]="filter.sortField === orderSortingFields.TotalAmount">
        <span title="{{order.totalAmount | exgNumber: '1.2-2'}}">{{order.totalAmount | exgCurrency:'symbol':'0.0-0'}}</span>
    </div>
    <div class="full-name responsible_users" *ngIf="listConfig[orderSortingFields.ResponsibleUsers]" [class.active]="filter.sortField === orderSortingFields.ResponsibleUsers">
        <span title="{{productResponsibleUsers}}">{{productResponsibleUsers}}</span>
    </div>
    <div class="full-name date" *ngIf="listConfig[orderSortingFields.ShippingDate]" [class.active]="filter.sortField === orderSortingFields.ShippingDate">
        <span title="{{order.shippingDate | exgDate: 'longDate'}}">{{order.shippingDate | exgDate: 'longDate'}}</span>
    </div>
    <div class="full-name status" *ngIf="listConfig[orderSortingFields.Status]" [class.active]="filter.sortField === orderSortingFields.Status">
        <span [class]="orderStatus[order.status]" title="{{orderStatus[order.status] | translate}}">{{orderStatus[order.status] | translate}}</span>
    </div>
</button>