import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../../../burns-ui-framework/shared/services/common/http.service';

import { OrganizationPosesListGetFilter } from '../../models/filters/organization-pos-list-get-filter.model';
import { OrganizationPosListItem } from '../../models/business/organization-pos/organization-pos-list-item.model';
import { PaginationResult } from '../../../../burns-ui-framework/shared/models/common/pagination-result.model';
import { OrganizationPosCreateOrUpdateRequest } from '../../models/business/organization-pos/pos-create-or-update-request.model';
import { PosDetailed } from '../../models/business/organization-pos/pos-detailed.model';
import { PosPaymentType } from '../../models/business/organization-pos/pos-payment-type.model';
import { PosRestrictionsRequest } from '../../models/business/organization-pos/pos-restrictions-request.model';
import { PosRestrictions } from '../../models/business/organization-pos/pos-restrictions.model';
import { OrganizationPos } from '../../models/business/organization-pos/pos.model';

import { String } from '../../../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class OrganizationPosService extends BaseSingletonService {

    private settings: {
        service: {
            getPos: string;
            getPosList: string;
            getPosByCoordinates: string;
            getPosesRestrictions: string;
            getPosesRestrictionsByArea: string;
            createPos: string;
            updatePos: string;
            deletePos: string;

            getPosPaymentTypes: string;
        }
    };

    constructor(private http: HttpService) {
        super('OrganizationPosService');
        this.settings = {
            service: {
                getPos: '/corporation/v1/poses/{0}',
                getPosList: '/corporation/v1/poses?organizationUid={0}&term={1}&sortField={2}&sortOrder={3}&pageIndex={4}&pageSize={5}&franchiseeUid={6}&isAvailable={7}',
                getPosByCoordinates: '/corporation/v1/poses/by-coordinates?longitude={0}&latitude={1}&date={2}',
                getPosesRestrictions: '/corporation/v1/poses/restrictions',
                getPosesRestrictionsByArea: '/corporation/v1/poses/{0}/areas/{1}/restrictions?date={2}&fixedDateFlag={3}',
                createPos: '/corporation/v1/poses',
                updatePos: '/corporation/v1/poses/{0}',
                deletePos: '/corporation/v1/poses/{0}',

                getPosPaymentTypes: '/corporation-aggregation/v1/payment-types?organizationPosUid={0}',
            }
        };
    }

    public async getPos(uid: string): Promise<OrganizationPos> {
        return this.http.get<{ data: OrganizationPos }>(String.format(this.settings.service.getPos, uid))
            .then(res => res.data);
    }

    public async getPosList(filter: OrganizationPosesListGetFilter): Promise<PaginationResult<OrganizationPosListItem>> {
        return this.http.get<PaginationResult<OrganizationPosListItem>>(String.format(this.settings.service.getPosList, filter.organizationUid, filter.term, filter.sortField, filter.sortOrder, filter.pageIndex, filter.pageSize, filter.franchiseeUid, filter.isAvailable))
            .then((res) => {
                res.pageSize = filter.pageSize;
                res.pageIndex = filter.pageIndex;
                return res;
            });
    }

    public async getPosByCoordinates(longitude: number, latitude: number, date: number): Promise<PosDetailed> {
        return this.http.get<{ data: PosDetailed }>(String.format(this.settings.service.getPosByCoordinates, longitude, latitude, date))
            .then(res => res.data);
    }

    public async getPosesRestrictions(request: PosRestrictionsRequest): Promise<PosRestrictions[]> {
        return this.http.post<{ list: PosRestrictions[] }>(String.format(this.settings.service.getPosesRestrictions), request)
            .then(res => res.list);
    }

    public async getPosesRestrictionsByArea(uid: string, areaUid: string, date: number, fixedDateFlag: boolean): Promise<PosRestrictions[]> {
        return this.http.get<{ list: PosRestrictions[] }>(String.format(this.settings.service.getPosesRestrictionsByArea, uid, areaUid, date, fixedDateFlag))
            .then(res => res.list);
    }

    public async createPos(request: OrganizationPosCreateOrUpdateRequest): Promise<OrganizationPos> {
        return this.http.post<{ data: OrganizationPos }>(String.format(this.settings.service.createPos), request)
            .then(res => res.data);
    }

    public async updatePos(uid: string, request: OrganizationPosCreateOrUpdateRequest): Promise<OrganizationPos> {
        return this.http.put(String.format(this.settings.service.updatePos, uid), request);
    }

    public async deletePos(uid: string): Promise<boolean> {
        return this.http.delete(String.format(this.settings.service.deletePos, uid));
    }

    public async getPosPaymentTypes(organizationPosUid: string): Promise<PosPaymentType[]> {
        return this.http.get<{ list: PosPaymentType[] }>(String.format(this.settings.service.getPosPaymentTypes, organizationPosUid))
            .then(res => res.list);
    }
}
