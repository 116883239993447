import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../shared/services/common/base-singleton.service';
import { HttpService } from '../../shared/services/common/http.service';
import { LanguageService } from '../../shared/services/common/language-service.service';

import { YandexPlaceComponent } from '../models/yandex-place-component.model';
import { YandexPlaceInputComponentType } from '../models/yandex-place-input-component-type.enum';
import { YandexSuggestionPlaceComponent } from '../models/yandex-suggestion-place-component.model';

import { String } from '../../shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class YandexService extends BaseSingletonService {

    private settings: {
        service: {
            getSuggestions: string;
            getPlaces: string;
            getPlacesReverse: string;
        }
    };

    constructor(private http: HttpService, private languageService: LanguageService) {
        super('YandexService');
        this.settings = {
            service: {
                getSuggestions: '/geocoding/v1/yandex/suggestions?term={0}&level={1}&fullNameLevelFrom={2}&lowerCornerLatitude={3}&lowerCornerLongitude={4}&upperCornerLatitude={5}&upperCornerLongitude={6}&culture={7}',
                getPlaces: '/geocoding/v1/yandex/places?geocode={0}&uri={1}&culture={2}',
                getPlacesReverse: '/geocoding/v1/yandex/places-reverse?latitude={0}&longitude={1}&culture={2}&level={3}&fullNameLevelFrom={4}'
            }
        };
    }

    public async getSuggestions(term: string, level: YandexPlaceInputComponentType, fullNameLevelFrom: YandexPlaceInputComponentType, lowerCornerLatitude: number, lowerCornerLongitude: number, upperCornerLatitude: number, upperCornerLongitude: number): Promise<YandexSuggestionPlaceComponent[]> {
        const culture = this.languageService.retrieveLanguage();
        return this.http.get<{ list: YandexSuggestionPlaceComponent[] }>(String.format(this.settings.service.getSuggestions, term, level, fullNameLevelFrom, lowerCornerLatitude, lowerCornerLongitude, upperCornerLatitude, upperCornerLongitude, culture))
            .then(res => res.list);
    }

    public async getPlaces(geocode: string, uri: string): Promise<YandexPlaceComponent[]> {
        const culture = this.languageService.retrieveLanguage();
        return this.http.get<{ list: YandexPlaceComponent[] }>(String.format(this.settings.service.getPlaces, geocode, uri, culture))
            .then(res => res.list);
    }

    public async getPlacesReverse(latitude: number, longitude: number, level: YandexPlaceInputComponentType, fullNameLevelFrom: YandexPlaceInputComponentType): Promise<YandexPlaceComponent[]> {
        const culture = this.languageService.retrieveLanguage();
        return this.http.get<{ list: YandexPlaceComponent[] }>(String.format(this.settings.service.getPlacesReverse, latitude, longitude, culture, level, fullNameLevelFrom))
            .then(res => res.list);
    }
}
